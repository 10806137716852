/*global Set */
/**
 * Represents a controller for a data table.
 * @class
 * @extends Controller
 */
import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import moment from 'moment';
import 'daterangepicker';
import dataTable from '../components/datatable';
import dataTableData from '../constants/tableConstants';

const IndexOne = 0;
const IndexTwo = 1;
const EmptyLength = 0;

export default class extends Controller {
  static targets = [
    'context',
    'table',
    'searchInput',
    'span',
    'rangePicker',
    'exportUrl',
    'title',
    'primaryLinkUrl',
    'primaryLinkText',
    'secondaryLinkUrl',
    'secondaryLinkText',
    'exportSuccessful',
    'exportError',
    'exportErrorMessage',
    'exportEmailList',
    'exportInputEmail',
    'exportFormatInput',
    'closeModalButton',
    'actionLoader',
  ];
  /**
   * Represents the columns configuration for a data table.
   * @type {Array<Object>}
   */
  urlWithStatusParams = null;
  filterStatus = null;
  redrawTableOptions = null;
  columns = null;
  ajaxURL = null;
  primaryLinkUrl = null;
  exportURL = null;
  primaryLinkText = null;
  secondaryLinkText = null;
  secondaryAddLinkText = null;
  csrf_token = document.querySelector('input[name=csrfmiddlewaretoken]');
  title = null;
  exportUniqueEmails = new Set(); //
  emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  ajaxParams = { created__lte: null, created__gte: null, search: null };
  startDate = moment().subtract(29, 'days');
  endDate = moment();
  ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
    'Last 3 Months': [moment().subtract(3, 'months'), moment()],
    'Last 6 Months': [moment().subtract(6, 'months'), moment()],
    'This Year': [moment().startOf('year'), moment()],
    'Last Year': [
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year'),
    ],
    'All Time': [moment().subtract(10, 'years'), moment()],
  };

  /**
   * Connects the data table controller.
   */
  connect() {
    this.updateElements();
    this.toggleExportButtonDisplay();
    this.initRangeFilters();
    this.drawTable();
    this.updateHtml();
    this.setDateRangePickerListeners();
  }

  setDateRangePickerListeners() {
    $(this.rangePickerTarget).on('apply.daterangepicker', (ev, picker) => {
      this.ajaxParams.created__lte = picker.endDate.format('YYYY-MM-DD');
      this.ajaxParams.created__gte = picker.startDate.format('YYYY-MM-DD');
      this.drawTable();
    });
    $(this.rangePickerTarget).on('cancel.daterangepicker', () => {
      this.spanTarget.textContent = 'start day - end day';
      this.ajaxParams.created__lte = null;
      this.ajaxParams.created__gte = null;
      this.drawTable();
    });
  }

  /**
   * Updates the elements of the data table controller.
   */
  updateElements() {
    let tableContext = this.contextTarget.dataset.tableContext;
    let pageContext = this.getTableContext(tableContext);
    this.title = pageContext.title;
    this.ajaxURL = pageContext.ajaxURL;
    this.columns = pageContext.columns;
    this.exportURL = pageContext.exportURL;
    let create_new_object_link = pageContext.addURLs;
    this.assignLinks(create_new_object_link);
  }

  getTableContext(context) {
    return dataTableData[context];
  }

  toggleExportButtonDisplay() {
    if (this.exportURL) {
      this.exportUrlTarget.removeAttribute('hidden');
    } else {
      this.exportUrlTarget.setAttribute('hidden', true);
    }
  }

  /**
   * Updates the HTML elements with the appropriate values.
   */
  updateHtml() {
    this.titleTarget.textContent = this.title;
    if (this.primaryLinkUrl) {
      this.primaryLinkUrlTarget.removeAttribute('hidden');
      this.primaryLinkUrlTarget.href = this.primaryLinkUrl;
      this.primaryLinkTextTarget.innerHTML = this.primaryLinkText;
    } else {
      this.primaryLinkUrlTarget.setAttribute('hidden', 'true');
      this.primaryLinkUrlTarget.href = '';
      this.primaryLinkTextTarget.innerHTML = '';
    }

    if (this.secondaryLinkUrl) {
      this.secondaryLinkUrlTarget.removeAttribute('hidden');
      this.secondaryLinkUrlTarget.href = this.secondaryLinkUrl;
      this.secondaryLinkTextTarget.textContent = this.secondaryLinkText;
    }
  }

  assignLinks(addURLs) {
    if (addURLs.length === EmptyLength) return;

    const firstItem = addURLs[IndexOne];
    this.primaryLinkUrl = firstItem.url;
    this.primaryLinkText = firstItem.name;

    if (addURLs.length > IndexTwo) {
      const secondItem = addURLs[IndexTwo];
      this.secondaryLinkUrl = secondItem.url;
      this.secondaryLinkText = secondItem.name;
    }
  }

  /**
   * Draws the table using the specified parameters.
   */
  drawTable() {
    dataTable(this.tableTarget, this.ajaxURL, this.ajaxParams, this.columns);
  }

  /**
   * Performs a search on the table based on the value entered in the search input field.
   */
  searchTable() {
    let searchValue = this.searchInputTarget.value.trim();
    this.ajaxParams.search = searchValue;
    this.drawTable();
  }

  /**
   * Initializes the range filters for the data table controller.
   */
  initRangeFilters() {
    let spanElement = this.spanTarget;

    function pickerCallBack(startDate, endDate) {
      spanElement.textContent =
        startDate.format('MMMM D, yyyy') +
        ' - ' +
        endDate.format('MMMM D, yyyy');
    }

    $(this.rangePickerTarget).daterangepicker(
      {
        startDate: this.startDate,
        endDate: this.endDate,
        ranges: this.ranges,
      },
      pickerCallBack,
    );
    pickerCallBack(this.startDate, this.endDate);
    this.postInitRangeFilters();
  }

  postInitRangeFilters() {
    // To be overridden by subclasses
  }

  performEmailInput(event) {
    if (event.key !== 'Enter' && event.key !== ' ' && event.type != 'click') {
      return;
    }

    let email = this.exportInputEmailTarget.value.trim();
    let emailExistError = 'Oops! This email is already on the list.';
    let invalidEmailError = 'Please enter a valid email address.';

    if (email !== '' && this.emailRegex.test(email)) {
      if (!this.exportUniqueEmails.has(email)) {
        this.exportErrorMessageTarget.innerText = '';
        this.exportUniqueEmails.add(email);
        this.populateEmailList();
        this.exportInputEmailTarget.value = '';
      } else {
        this.exportErrorMessageTarget.innerText = emailExistError;
      }
    } else {
      this.exportErrorMessageTarget.innerText = invalidEmailError;
    }
  }

  populateEmailList() {
    this.exportEmailListTarget.innerHTML = '';
    this.exportUniqueEmails.forEach(email => {
      const emailTagHTML = this.emailListInnerHtml(email);
      this.exportEmailListTarget.insertAdjacentHTML('beforeend', emailTagHTML);
    });
  }

  emailListInnerHtml(email) {
    return (
      '<div class="d-inline-block me-2 mb-2">' + // Add class d-inline-block for inline-block display
      '<div class="alert alert-success alert-dismissible fade show email-tag">' +
      `<strong>${email}</strong>` +
      `<button data-email="${email}" data-action="click->data-table#removeEmail" type="button" class="btn-close" aria-label="Close">` +
      '</button>' +
      '</div>' +
      '</div>'
    );
  }

  removeEmail(event) {
    let button = event.target.closest('button');
    let email = button.getAttribute('data-email');

    if (this.exportUniqueEmails.has(email)) {
      this.exportUniqueEmails.delete(email);
      this.populateEmailList();
    } else {
      return;
    }
  }

  closeModal() {
    this.exportUniqueEmails.clear();
    this.exportEmailListTarget.innerHTML = '';
    $(this.closeModalButtonTarget).trigger({ type: 'click' });
  }

  async exportPost(url, data) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'X-CSRFToken': this.csrf_token.value,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        console.error(response);
        return;
      } else {
        let response_data = await response.json();
        let response_status = response.status;
        return { json: response_data, status: response_status };
      }
    } catch (error) {
      console.error(error);
    }
  }

  exportData() {
    if (this.exportUniqueEmails.size === 0) {
      this.exportErrorMessageTarget.innerText =
        'Please add at least one email to the list.';
      return;
    }
    this.actionLoaderTarget.removeAttribute('hidden');
    let postData = {
      emails: Array.from(this.exportUniqueEmails),
      data_format: this.exportFormatInputTarget.value,
      start_date: this.ajaxParams.created__gte || '',
      end_date: this.ajaxParams.created__lte || '',
      search_input: this.ajaxParams.search || '',
      password: '',
      datatable_url:
        this.urlWithStatusParams !== null
          ? `${window.location.origin}${this.urlWithStatusParams}`
          : `${window.location.origin}${this.ajaxURL}`,
    };
    this.exportPost(this.exportURL, postData).then(responseData => {
      this.actionLoaderTarget.setAttribute('hidden', true);
      if (responseData && responseData.status === 200) {
        this.postSuccess();
      } else {
        this.postError();
      }
    });
  }

  postSuccess() {
    this.closeModal();
    this.exportSuccessfulTarget.removeAttribute('hidden');
  }

  postError() {
    this.closeModal();
    this.exportErrorTarget.removeAttribute('hidden');
  }

  reDrawTable() {
    dataTable(
      this.redrawTableOptions.tableTarget,
      this.redrawTableOptions.ajaxURL,
      this.redrawTableOptions.ajaxParams,
      this.redrawTableOptions.columns,
      this.redrawTableOptions.selectable,
    );
  }

  setActiveButton(activeElement) {
    // Query all buttons with the attribute data-status
    const buttons = document.querySelectorAll('button[data-status]');

    // Remove the 'active' class from all buttons
    buttons.forEach(button => {
      button.classList.remove('bg-success');
      button.classList.remove('text-white');
      button.style.color = '';
    });

    // Add the 'active' class to the passed activeElement
    activeElement.classList.add('bg-success');
    activeElement.style.color = 'white';
  }

  filterByStatus(event) {
    let statusButton = event.target;
    let status = statusButton.dataset.status;
    let initialUrl = this.ajaxURL;
    let urlWithStatusParams = initialUrl;
    let activeButton = statusButton;

    if (status !== 'ALL') {
      urlWithStatusParams = initialUrl.includes('?')
        ? `${initialUrl}&status=${status}`
        : `${initialUrl}?status=${status}`;
      this.urlWithStatusParams = urlWithStatusParams;
    }

    this.setActiveButton(activeButton);
    this.redrawTableOptions = {
      tableTarget: this.tableTarget,
      ajaxURL: status == 'ALL' ? initialUrl : urlWithStatusParams,
      ajaxParams: this.ajaxParams,
      columns: this.columns,
    };

    if (status === 'ALL') {
      this.ajaxURL = urlWithStatusParams;
      this.filterStatus = status;
    }

    this.reDrawTable();
    this.postFilterByStatus(status);
  }

  postFilterByStatus() {}
}
